import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class PlanService {
  baseUrl: string = environment.baseUrlAPI;
  plansUrl: string = this.baseUrl+'/consumptionplans';
  loggedMasterUser:any

  constructor(private hc: HttpClient) {
    this.loggedMasterUser = localStorage.getItem('userToken');
    if (this.loggedMasterUser) {
      this.loggedMasterUser = JSON.parse(this.loggedMasterUser);
    }
  }

  newPlan(data: any): Observable<any> {
    return this.hc.post<any>(this.plansUrl, data);
  }

  getPlans(plantype:string): Observable<any> {
    return this.hc.get<any>(this.plansUrl+plantype);
  }

  getPlan(id: number): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.get<any>(`${this.plansUrl}/by-id/${id}`, { headers });
  }

  updatePlan(id: string, data: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });

    return this.hc.put<any>(`${this.plansUrl}/${id}`, data, { headers });
  }
  updatePlanConsumptions(): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });

    return this.hc.put<any>(`${this.plansUrl}`,  { headers });
  }

  exportConsumptionsPlans(data: any, options: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });

    return this.hc.post(`${this.plansUrl}/export`, data, { ...options, headers, responseType: 'blob' });
  }

  exportConsumptionsPlansExportSubsite(data: any, options: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });

    return this.hc.post(`${this.baseUrl}/measure/exportFinal`, data, { ...options, headers, responseType: 'blob' });
  }
  // exportConsumptionsPlans(data: any, options: any): Observable<any> {
  //   // Ensure headers are included in the options
  //   return this.hc.post(`${this.plansUrl}/export`, data, options);
  // }


  deletePlan(id: number): Observable<any> {
    return this.hc.delete<any>(`${this.plansUrl}/${id}`);
  }
}
