<title>1 Month Forecast | MyEnergy</title>
<meta name="description" content="1 Month Forecast. Average daily prices" />

<div class="row">
  <div class="col">
    <h1 class="text-center mt-3">1 Month Forecast</h1>
    <h5 class="text-center mb-3">Average daily prices</h5>

    <div class="row">
      <div class="col-12 col-sm-6 col-lg-5">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01"
              >Market</label
            >
          </div>
          <select
            class="custom-select"
            [(ngModel)]="region"
            (change)="setRegion()"
            id="inputGroupSelect01"
          >
            <option selected [ngValue]="'sk'">Slovakia</option>
            <option [ngValue]="'cz'">Czech Republic</option>
            <option [ngValue]="'hu'">Hungary</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-2"></div>
      <div class="col-12 col-sm-6 col-lg-5">
        <div class="input-group mb-3 text-right">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01"
              >From:</label
            >
          </div>
          <input
            [(ngModel)]="dateFrom"
            type="date"
            class="form-control"
            aria-label="Text input with checkbox"
            value="2023-11-03"
            (change)="gatherPlotData()"
          />
        </div>
      </div>
    </div>

    <body class="graf img-fluid" #graf></body>

    <div>
      <!-- <ngx-uplot [data]="plotData" [options]="plotOptions" [legend]="true">
      </ngx-uplot> -->
      <div
        echarts
        [options]="chartOption"
        [loading]="isLoading"
        theme="macarons"
        class="demo-chart"
      ></div>
    </div>
    <div class="row p-3"></div>

    <table class="table table-hover table-responsive" *ngIf="dailyPricesLoaded">
      <thead>
        <tr>
          <th>Month:</th>
          <th
            style="text-align: center"
            *ngFor="let dailyPrice of dailyPrices"
            class="table-header"
          >
            {{ dailyPrice["month"] }}
          </th>
        </tr>
        <tr>
          <th>Day:</th>
          <th
            style="text-align: center"
            *ngFor="let dailyPrice of dailyPrices"
            class="table-header"
          >
            {{ dailyPrice["day"] }}
          </th>
        </tr>
        <tr>
          <th>({{ unit }})</th>
          <th
            style="text-align: center"
            *ngFor="let dailyPrice of dailyPrices"
            class="table-header"
          >
            {{ dailyPrice["dayOfWeek"] }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td [ngStyle]="{ 'background-color': plotColors[0] }">Forecast 1:</td>
          <td style="text-align: center" *ngFor="let dailyPrice of dailyPrices">
            {{ dailyPrice.avgPrices[0] }}
          </td>
        </tr>
        <tr>
          <td [ngStyle]="{ 'background-color': plotColors[1] }">Forecast 2:</td>
          <td style="text-align: center" *ngFor="let dailyPrice of dailyPrices">
            {{ dailyPrice.avgPrices[1] }}
          </td>
        </tr>
        <tr>
          <td [ngStyle]="{ 'background-color': plotColors[2] }">Forecast 3:</td>
          <td style="text-align: center" *ngFor="let dailyPrice of dailyPrices">
            {{ dailyPrice.avgPrices[2] }}
          </td>
        </tr>
        <tr>
          <td [ngStyle]="{ 'background-color': plotColors[3] }">Forecast 4:</td>
          <td style="text-align: center" *ngFor="let dailyPrice of dailyPrices">
            {{ dailyPrice.avgPrices[3] }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="float-sm-right m-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="currentColor"
        class="bi bi-printer-fill fa-3x"
        viewBox="0 0 16 16"
      >
        <path
          d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"
        />
        <path
          d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="currentColor"
        class="bi bi-box-arrow-down"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z"
        />
        <path
          fill-rule="evenodd"
          d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"
        />
      </svg>
    </div>
  </div>
</div>
