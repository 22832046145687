<title>MyEnergy : Business Units & Locations</title>
<meta name="description"
  content="Digitalisation price tracker of MyEnergy is tracking energy prices in real time and provides a precise forecast." />

<div class="pageTemplate">
  <!-- <div class="text-center mt-3">
    <div class="spinner-border text-info" role="status"></div>
  </div> -->
  <div>
    <div class="headingBlock" >
      <div class="headingBlock__title site__title text-center m-3" *ngIf="!isLoading">
        <!-- {{ "priceToday.title" | translate }} -->
        Business Units & Locations
        <span style="color: #00bf63">
          {{ clientData?.name + " ( " + clientData?.shortName + " ) " }}
        </span>
      </div>
    </div>

    <body class="graf img-fluid" #graf></body>


    <div class="text-center" *ngIf="isLoading">
      <div class="spinner-border text-info" role="status"></div>
    </div>

    <div style="margin: auto;text-align: center;margin-top: 30px;
    margin-bottom: 30px;" *ngIf="tree && !isLoading">
      <angular-org-chart *ngIf="tree" [nodeTemplate]="nodeTemplate" [data]="tree" #exampleChart="orgChart">
      </angular-org-chart>
      <ng-template #nodeTemplate let-node>
        <div class="chart-node">
          <div class="name">{{node?.name}}</div>
          <div class="description">{{node?.description}} </div>
        </div>
      </ng-template>
    </div>


    <div class="requestList" *ngIf="!isLoading">
      <div class="container-fluid">
        <div class="userListingFlex">
          <div class="userListing">
            <div class="userListing__card">
              <div class="input-group mb-3" style="margin-top: 10px;">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01">Business Unit</label>
                </div>
                <select class="custom-select" [(ngModel)]="selectedBusinessUnit"
                  (ngModelChange)="setBusinessUnit($event)" id="inputGroupSelect01">
                  <option value="" style="color: gray;">
                    New Business Unit...
                  </option>
                  <option [ngValue]="item.id" *ngFor="let item of businessUnits">
                    {{ item.identifier }}
                  </option>
                </select>
              </div>

              <h5>{{selectedBusinessUnit?'Update a Business Unit:':'Create a new Business Unit:'}}</h5>

              <div class="fields">
                <mat-label>Business Unit's Name:</mat-label>
                <mat-form-field class="example-full-width">
                  <input matInput [(ngModel)]="selectedBusinessUnitForEdit.identifier" />
                </mat-form-field>
              </div>

              <div class="fields">
                <mat-label>Business Unit's Description: </mat-label>
                <mat-form-field class="example-full-width">
                  <input matInput [(ngModel)]="selectedBusinessUnitForEdit.description" />
                </mat-form-field>
              </div>

              <div class="buttons">
                <button class="addBtn"
                  (click)="addUpdateBusinessUnit()">{{selectedBusinessUnit?'Update':'Create'}}</button>
                <button class="deleteBtn" (click)="deleteBusinessUnit()"
                  [ngStyle]="{ 'background-color': (selectedBusinessUnitForEdit.status === 'active' || selectedBusinessUnit=='') ? 'rgb(255, 127, 127)' : 'red' }">Delete</button>
                <button class="cancelBtn"
                  (click)="selectedBusinessUnit='';selectedBusinessUnitForEdit={}">Cancel</button>

              </div>
            </div>
          </div>

          <div class="userListing">
            <div class="userListing__card">
              <div class="input-group mb-3" style="margin-top: 10px;">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01">{{
                    "happenedYesterday.locations" | translate
                    }}</label>
                </div>
                <select class="custom-select" [(ngModel)]="selectedLocation" (ngModelChange)="setLocation($event)"
                  id="inputGroupSelect01">
                  <option value="" style="color: gray;">
                    New Location...
                  </option>
                  <option [ngValue]="item.id" *ngFor="let item of locations">
                    {{ capitalizeFirstLetter(item.identifier) }}
                  </option>
                </select>
              </div>

              <h5>{{selectedLocation?'Update a Location:':'Create a new Location:'}}</h5>

              <div class="fields">
                <label for="">Location's Business Unit:</label>
                <select class="custom-select" [(ngModel)]="selectedLocationForEdit.businessunit_id"
                  id="inputGroupSelect01">
                  <option [ngValue]="item.id" *ngFor="let item of businessUnits">
                    {{ capitalizeFirstLetter(item.identifier) }}
                  </option>

                </select>
              </div>

              <div class="fields">
                <mat-label>Location's Name:</mat-label>
                <mat-form-field class="example-full-width">
                  <input matInput [(ngModel)]="selectedLocationForEdit.identifier" />
                </mat-form-field>
              </div>
              <div class="fields">
                <mat-label>Location's Description:</mat-label>
                <mat-form-field class="example-full-width">
                  <input matInput [(ngModel)]="selectedLocationForEdit.description" />
                </mat-form-field>
              </div>

              <div class="buttons">
                <button class="addBtn" (click)="addUpdateLocation()">{{selectedLocation?'Update':'Create'}}</button>
                <button class="deleteBtn" (click)="deleteLocation()"
                  [ngStyle]="{ 'background-color': (selectedLocationForEdit.status === 'active'|| selectedLocation=='') ? 'rgb(255, 127, 127)' : 'red' }">Delete</button>
                <button class="cancelBtn" (click)="selectedLocation='';selectedLocationForEdit={}">Cancel</button>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
