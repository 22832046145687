import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivityLogsService } from './activity-logs.service';
import { FormControl } from '@angular/forms';
import { AdminService } from '../admin/admin.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-activity-logs',
  templateUrl: './activity-logs.component.html',
  styleUrls: ['./activity-logs.component.scss'],
})
export class ActivityLogsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  activities: any = [
    {
      key: 'consumptionplans',
      display: 'Consumptions Plans',
    },
    {
      key: 'consumptionplans/day',
      display: 'Consumptions Plans(Day)',
    },
    {
      key: 'consumptionplans/intraday',
      display: 'Consumptions Plans(Intraday)',
    },
    {
      key: 'contactus',
      display: 'Contact Us',
    },
    {
      key: 'registrations',
      display: 'Registrations',
    },
    {
      key: 'users',
      display: 'Users',
    },
  ];
  activityLogs: any;
  startDate: any = new FormControl();
  endDate: any = new FormControl();
  userlist: any;
  payload: any;
  selectedUser: any = '';
  loadingFinished: boolean = true;
  clientData: any;
  displayedColumns: string[] = [
    'user_id',
    'activity',
    'description',
    'created_at',
    'ip_address',
    'actions',
  ];
  dataSource: MatTableDataSource<any>;
  parsedData: any;

  constructor(
    private activityService: ActivityLogsService,
    private adminService: AdminService
  ) {}

  ngOnInit() {
    this.activityService.getActivities().subscribe((data) => {});
    this.payload = {
      activity: '',
      dateFrom: null,
      dateTo: null,
    };
    let loggedMasterUser: any = localStorage.getItem('userToken');
    if (loggedMasterUser) {
      loggedMasterUser = JSON.parse(loggedMasterUser);
      this.adminService.getUsers().subscribe((data: any) => {
        if (
          loggedMasterUser.role == 'master user' ||
          loggedMasterUser.role == 'user'
        ) {
          this.userlist = data.filter(
            (item: any) => item.client_id == loggedMasterUser?.client_id
          );
        } else {
          this.userlist = data;

        }
      });
      this.adminService
        .getClient(loggedMasterUser.client_id)
        .subscribe((data) => {
          this.clientData = data;
        });
    }
    this.getActivityLogs();
  }
  getCreatedByName(id: number) {
    let user: any = this.userlist.find((item: any) => item.id === id);
    return user?.fullName?.charAt(0).toUpperCase() + user?.fullName?.slice(1) || user.login;
  }

  getActivityLogs() {
    this.loadingFinished = false;
    if (this.payload.dateFrom)
      this.payload.dateFrom = new Date(this.payload.dateFrom).toISOString();
    if (this.payload.dateTo)
      this.payload.dateTo = new Date(this.payload.dateTo).toISOString();
    this.activityService.getActivityLogs(this.payload).subscribe((data) => {
      if (this.selectedUser)
        this.activityLogs = data.data.filter(
          (item: any) => item.user_id === +this.selectedUser
        );
      else this.activityLogs = data.data;

      this.activityLogs.sort((a: any, b: any) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });
      for (let plan of this.activityLogs) {
        plan.created_at = plan.created_at.split('.')[0].replace('T', ' ');
        plan.updated_at = plan.updated_at.split('.')[0].replace('T', ' ');
      }
      // this.activityLogs = this.activityLogs.filter(
      //   (item: { description: string }) => item.description !== 'GET'
      // );
      this.dataSource = new MatTableDataSource(this.activityLogs);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loadingFinished = true;
    });
  }
  capitalizeFirstLetter(value: string): string {
    if (value && typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }
  viewModal(element: any) {
    this.parsedData = Object.entries(JSON.parse(element.data));
  }
  getMethodName(type: string) {
    if (type == 'POST') return 'CREATE';
    else if (type == 'PUT') return 'UPDATE';
    else return type;
  }
}
