import { Component } from '@angular/core';
import { AdminService } from '../admin.service';
import { Client } from '../client';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent {
  possibleRegions: string[] = ['sk', 'cz', 'hu', 'pl'];
  role!:any
  clientList: Client[] = [];
  newClient: Client;

  newClientMenuOpen: boolean = true;

  readonly:boolean=true

  loadingFinished: boolean = true;
  newMasterClient = {
    clientshortName: '',
    clientOfficialName: '',
    clientOfficialAddress: '',
    countryOfRegisteredSeat: '',
    webpage: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    passwrod: '',
    confirmPassword: '',
    status: '',
  };

  constructor(private adminService: AdminService) {}

  ngOnInit() {
    this.initializeView();
    let data=localStorage.getItem('newMasterClient')
    if(data){
      let Temp=JSON.parse(data)
      this.newMasterClient=Temp
    }
  }

  initializeView() {
    this.loadingFinished = false;
    this.adminService.getClients().subscribe({
      next: (data) => {
        this.constructClientList(data);
        this.loadingFinished = true;
      },
    });
    this.newClient = {
      name: '',
      address: '',
      region: '',
      webpage: '',
      phone: '',
      status: 'O',
    };
  }

  // toggleNewClientMenu() {
  //   this.newClientMenuOpen = !this.newClientMenuOpen;
  // }

  constructClientList(data: Client[]) {
    this.clientList = [];
    data.forEach((clientData) => {
      let client: Client = {
        id: clientData.id,
        name: clientData.name,
        address: clientData.address,
        region: clientData.region,
        webpage: clientData.webpage,
        phone: clientData.phone,
        status: clientData.status,
        created_at: clientData.created_at?.split('T')[0],
        updated_at: clientData.updated_at?.split('T')[0],
      };
      if (!client.created_at) {
        client.created_at = '[Unknown]';
      }
      if (!client.updated_at) {
        client.updated_at = '[Unknown]';
      }
      if (!client.webpage) {
        client.webpage = 'none';
      }
      if (!client.phone) {
        client.phone = 'none';
      }
      this.clientList.push(client);
    });
  }

  editClientEnable(){
    this.readonly=this.readonly?false:true
  }
  createClient() {
    //console.log('Creating client ' + this.newClient.name);
    this.adminService.newClient(this.newClient).subscribe({
      next: (data) => {
        this.initializeView();
        // this.newClientMenuOpen = true;
      },
    });
  }

  editClient(clientId: number) {
    let client = this.clientList.find((client) => client.id === clientId);
    //console.log('Editing plan ' + client?.name);
    let pageCode = JSON.stringify(client).toString();
    let page = window.open();
    page?.document.write(pageCode);
  }

  deleteClient(clientId: number) {
    let client = this.clientList.find((client) => client.id === clientId);
    //console.log('Deleting client ' + client?.name);
    this.adminService.deleteClient(clientId).subscribe({
      next: (data) => {
        this.initializeView();
      },
    });
  }
}
