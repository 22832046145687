import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Forecast1weekComponent } from './forecast1week/forecast1week.component';
import { Forecast1monthComponent } from './forecast1month/forecast1month.component';
import { Forecast14Component } from './forecast14/forecast14.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
  declarations: [
    Forecast1weekComponent,
    Forecast1monthComponent,
    Forecast14Component
  ],
  imports: [
    CommonModule, RouterModule, FormsModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    })
  ]
})
export class ForecastModule { }
