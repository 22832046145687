<title>MyEnergy Analysis: Actual consumption cost</title>
<meta name="description"
  content="Digitalisation price tracker of MyEnergy is tracking energy prices in real time and provides a precise forecast." />

<div class="pageTemplate">
  <div class="container">
    <div class="headingBlock">
      <div class="headingBlock__title site__title text-center m-3">
        {{ "priceToday.title" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-lg-4">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">{{
              "priceToday.market" | translate
              }}</label>
          </div>
          <select class="custom-select" [(ngModel)]="region" (change)="setRegion()" id="inputGroupSelect01">
            <option selected [ngValue]="'sk'">Slovakia</option>
            <option [ngValue]="'cz'">Czech Republic</option>
            <option [ngValue]="'hu'">Hungary</option>
          </select>
        </div>
      </div>

      <!-- <div class="col-12 col-sm-6 col-lg-3"></div> -->
      <div class="col-12 col-sm-6 col-lg-4">
        <div class="input-group mb-3 text-right">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">{{ "priceToday.from" | translate }}:</label>
          </div>
          <input type="date" [(ngModel)]="dateFrom" class="form-control" aria-label="Text input with checkbox"
            value="2023-11-03" (change)="generateChart()" />
        </div>
      </div>
      <!--
    </div>

    <div class="row">
    -->
      <!--
      <div class="col-12 col-sm-6 col-lg-3">
        <div class="input-group mb-3">
          <select class="custom-select" id="inputGroupSelect02">
            <option selected value="1">hourly data</option>
            <option value="2">weekly data</option>
            <option value="3">monthly data</option>
          </select>
        </div>
      </div>
      -->

      <!-- <div class="col-12 col-sm-6 col-lg-4"></div> -->
      <div class="col-12 col-sm-6 col-lg-4">
        <div class="input-group mb-3 text-right">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">{{ "priceToday.until" | translate }}:</label>
          </div>
          <input type="date" [(ngModel)]="dateUntil" class="form-control" aria-label="Text input with checkbox"
            value="2023-11-03" (change)="generateChart()" />
        </div>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-12 col-sm-4" data-toggle="tooltip" data-placement="top"
        title="{{ 'priceToday.tooltip.spot' | translate }}">
        <h2>{{ "priceToday.spot" | translate }}</h2>
        <div class="spotprice-box">{{ spotPrice }}€</div>
      </div>
      <div class="col-12 col-sm-4" data-toggle="tooltip" data-placement="top"
        title="{{ 'priceToday.tooltip.forecast' | translate }}">
        <h2>{{ "priceToday.forecast" | translate }}</h2>
        <div class="backcast-box">{{ forecastPrice }}€</div>
      </div>
      <div class="col-12 col-sm-2" data-toggle="tooltip" data-placement="top"
        title="{{ 'priceToday.tooltip.trend' | translate }}">
        <h2>{{ "priceToday.trend" | translate }}</h2>
        <div *ngIf="trendRising">
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#00bf63"
            class="bi bi-arrow-up-right-square" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.854 8.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z" />
          </svg>
        </div>
        <div *ngIf="!trendRising">
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#00bf6350"
            class="bi bi-arrow-down-right-square" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.854 3.146a.5.5 0 1 0-.708.708L9.243 9.95H6.475a.5.5 0 1 0 0 1h3.975a.5.5 0 0 0 .5-.5V6.475a.5.5 0 1 0-1 0v2.768z" />
          </svg>
        </div>
      </div>
      <div class="col-12 col-sm-2" data-toggle="tooltip" data-placement="top"
        title="{{ 'priceToday.tooltip.forecast' | translate }}">
        <h2>{{ "Semaphore" }}</h2>
        <div class="backcast-box">
          <img src="./assets/images/semaphore-{{semaphoreValues[0]}}.png" alt="">
          <img src="./assets/images/semaphore-{{semaphoreValues[1]}}.png" alt="">
          <img src="./assets/images/semaphore-{{semaphoreValues[2]}}.png" alt="">
        </div>
      </div>
    </div>
    <body class="graf img-fluid" #graf></body>
    <div>
      <!-- <ngx-uplot [data]="plotData" [options]="plotOptions" [legend]="true">
      </ngx-uplot> -->
      <div echarts [options]="chartOption" [loading]="isLoading" theme="macarons" class="demo-chart"></div>
    </div>
    <div class="row p-3"></div>
    <!-- <div class="row pb-2">
      <div class="col-12 m-0 text-start legend-title">
        {{ "priceToday.chartLegend.subject" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0">
        {{ "priceToday.chartLegend.spot" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0">
        {{ "priceToday.chartLegend.forecast" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0">
        {{ "priceToday.chartLegend.trend" | translate }}
      </div>
    </div>
    <div class="row p-2"></div>
    <div class="row">
      <div class="col-12 m-0 text-start black-text">
        {{ "priceToday.chartLegend.black" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0 text-start blue-text">
        {{ "priceToday.chartLegend.blue" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0 text-start green-text">
        {{ "priceToday.chartLegend.green" | translate }}
      </div>
    </div>
    <div class="row p-2"></div>
    <div class="row pb-2">
      <div class="col-12 m-0 legend-title">
        {{ "priceToday.chartLegend.instructions" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0">
        {{ "priceToday.chartLegend.instructions1" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0">
        {{ "priceToday.chartLegend.instructions2" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0">
        {{ "priceToday.chartLegend.instructions3" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0">
        {{ "priceToday.chartLegend.instructions4" | translate }}
      </div>
    </div>
  </div> -->
</div>
<div class="row p-3"></div>
