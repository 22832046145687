import { Component, ElementRef, ViewChild } from '@angular/core';
import { PhoneState } from '../phone-state';
import { HelpdeskService } from '../helpdesk.service';
import { UserService } from 'src/app/user/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
})

export class RequestComponent {
  @ViewChild('form') form: FormGroup;
  buttonContent: string = "submitNext";
  buttonClass: string = '';
  requestTitle: string = this.helpdeskService.getRequestTitle();
  requestId: string = this.helpdeskService.getRequestId();
  requestSubject: string = this.helpdeskService.getRequestSubject();
  myEnergyService: string = this.helpdeskService.getMyEnergyService();
  myEnergyServiceList: string[] = [
    'firt',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
  ];
  description: string = this.helpdeskService.getDescription();
  priority: string = this.helpdeskService.getPriority();
  phoneStateList: PhoneState[] = [
    { prefix: '+421', name: 'Slovakia' },
    { prefix: '+420', name: 'Czech Republic' },
    { prefix: '+48', name: 'Poland' },
    { prefix: '+36', name: 'Hungary' },
    { prefix: '+49', name: 'Germany' },
    { prefix: '+43', name: 'Austria' },
  ];
  phoneState: PhoneState = this.getPhoneState();
  phoneNumber: string = this.helpdeskService.getPhoneNumber();
  attachment: File | any = this.helpdeskService.getAttachment();
  email: string = this.helpdeskService.getEmail();

  showServiceDropdown: boolean = false;
  showStateDropdown: boolean = false;

  prefilledData: any;
  readonly: boolean ;

  userToken: any;

  registered: boolean;

  errSubjectX: number = 0;
  errSubjectY: number = 0;
  subjectInvalid: string = "";
  subjectMaxLength: number = 250;

  errDescX: number = 0;
  errDescY: number = 0;
  descInvalid: string = "";
  counter : number = 0;
  descMaxLength: number = 1500;

  errPhoneX: number = 0;
  errPhoneY: number = 0;
  phoneInvalid: string = "";
  phoneMaxLength: number = 11;

  constructor(
    private helpdeskService: HelpdeskService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    private elementRef: ElementRef,
    private translate: TranslateService
  ) {
      this.userToken = helpdeskService.userToken;
      this.prefilledData = helpdeskService.prefilledData;
      this.registered   = helpdeskService.getRegistered();
  }

  ngOnInit() {
    this.registered   = this.helpdeskService.getRegistered();
    //console.log("prefilledData: ", this.prefilledData);

    if (this.prefilledData) {
      //console.log("test prefilledData");
      this.requestSubject = this.helpdeskService.getRequestSubject();
      this.myEnergyService = this.helpdeskService.getMyEnergyService();
      this.description = this.helpdeskService.getDescription();
      this.priority = this.helpdeskService.getPriority();
      this.phoneNumber = this.helpdeskService.getPhoneNumber();
      this.email = this.helpdeskService.getEmail();
      this.readonly = true;
    }
  }

  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');

    if ( ! selectedLang ) selectedLang = "en";

    //console.log(`${selectedLang}/${url}`);
    this.router.navigate([`${selectedLang}/${url}`]);
    //console.log(`${selectedLang}/${url}`);
  }

  toogleServiceDropdown(event: Event): void {
    event.preventDefault();
    let toggle = this.showServiceDropdown;
    this.disableAllMenu();
    this.showServiceDropdown = !toggle;
  }

  disableAllMenu() {
    this.showServiceDropdown = false;
  }

  updateService(e: any) {
    this.showServiceDropdown = false;
  }

  btnSubmitClick(event: Event): void {
    this.buttonClass = 'btn-success';
  }

  onChangePriority(object: any): void {
    this.helpdeskService.setPriority(object.target.value);
    this.priority = this.helpdeskService.getPriority();
  }

  toogleStateDropdown(event: Event): void {
    event.preventDefault();
    let toggle = this.showStateDropdown;
    this.disableAllMenu();
    this.showStateDropdown = !toggle;
  }

  updateState(e: any) {
    this.helpdeskService.setPhoneState(e.target.value);
    this.phoneState = this.getPhoneState();
    this.showStateDropdown = false;
  }

  handleImport($event: any) {
    /* -----------==========================--------*/
    const files: File[] = $event.target.files;

    if (files.length) {
      const file: File = files[0];
      this.attachment = file;
    }
  }

  getPhoneState(): PhoneState {
    const prefix: string = this.helpdeskService.getPhoneState();
    var name: string = '';
    name = this.phoneStateList.find((p) => p.prefix === prefix)?.name;

    return { prefix: prefix, name: name };
  }

  isSubjectValid(event: any){
    this.errSubjectX = 0;
    this.errSubjectY = 0;
    this.subjectInvalid = "";
    this.counter = event.length;

    var element = this.elementRef.nativeElement.querySelector("#subject");

    if ( element?.offsetParent ){
      this.errSubjectX = element?.offsetParent.getBoundingClientRect().x;
      this.errSubjectY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errSubjectX = Math.abs(this.errSubjectX - element.getBoundingClientRect().x);
    this.errSubjectY = Math.abs(this.errSubjectY - element.getBoundingClientRect().y) - 25;

    // var x = this.calcX(0,element);
    // console.log("calcX: " + x);

    if ( event.length < 4 ){
      this.subjectInvalid = "subject";
    }

    if ( event.length > this.subjectMaxLength ){
      this.subjectInvalid = "subject";
    }

    return this.subjectInvalid;
  }

  isDescValid(event: any){
    this.errDescX = 0;
    this.errDescY = 0;
    this.descInvalid = "";
    this.counter = event.length;

    var element = this.elementRef.nativeElement.querySelector("#description1");

    if ( element?.offsetParent ){
      this.errDescX = element?.offsetParent.getBoundingClientRect().x;
      this.errDescY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errDescX = Math.abs(this.errDescX - element.getBoundingClientRect().x);
    this.errDescY = Math.abs(this.errDescY - element.getBoundingClientRect().y) - 25;

    //var x = this.calcX(0,element);
    //console.log("calcX: " + x);

    if ( event.length < 4 ){
      this.descInvalid = "description"
    }

    return this.descInvalid;    
  }

  isPhoneValid(event: any){
    this.errPhoneX = 0;
    this.errPhoneY = 0;
    this.phoneInvalid = "";
    this.form.controls['phoneNumber'].setErrors(null);
  
    var element = this.elementRef.nativeElement.querySelector("#phoneNumber");

    if ( element?.offsetParent ){
      this.errPhoneX = element?.offsetParent.getBoundingClientRect().x;
      this.errPhoneY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errPhoneX = Math.abs(this.errPhoneX - element.getBoundingClientRect().x);
    this.errPhoneY = Math.abs(this.errPhoneY - element.getBoundingClientRect().y) - 25;

    //var x = this.calcX(0,element);
    //console.log("calcX: " + x);

    if ( event.length < 6 ){
      this.phoneInvalid = "phoneMin";
    }

    if ( ! /^[\d \-]+$/.test(event) ) {    
      this.phoneInvalid = "phoneRegex";
      this.form.controls['phoneNumber'].setErrors({'incorrect': true});
    }

    //console.log("!!!!!!!somtu", this.form.valid);
    return this.phoneInvalid;
  }

  calcX(start: number, element: ElementRef| any): number{
    var x: number = start;
    x = x + element?.getBoundingClientRect().x;
    
    //console.log(element, element?.getBoundingClientRect().x);

    if ( element?.offsetParent ){
      x = this.calcX(x, element.offsetParent);
    }

    return x;
  }

  onSubmit() {
    if (
      !this.requestSubject ||
      !this.myEnergyService || this.myEnergyService === 'My Energy Service' ||
      !this.description ||
      !this.phoneNumber
    ) {
      // Если не все поля заполнены, выводим сообщение об ошибке

      this.translate.get('helpDesk.request.validator.toastrError').subscribe((data: string) => {
        this.toastr.error(data);
      });      
    
      // this.toastr.error('Please fill in all fields correctly!', '');
      return; // Прекращаем выполнение функции
    }

    this.helpdeskService.setRequestSubject(this.requestSubject);
    this.helpdeskService.setMyEnergyService(this.myEnergyService);
    this.helpdeskService.setDescription(this.description);
    this.helpdeskService.setPriority(this.priority);
    this.helpdeskService.setPhoneState(this.phoneState.prefix);
    this.helpdeskService.setPhoneNumber(this.phoneNumber);
    this.helpdeskService.setAttachment(this.attachment);
    this.helpdeskService.setEmail(this.email);
    this.helpdeskService.setRequest(false);
    this.helpdeskService.setRequestTitle('Your help desk request');

    this.helpdeskService.setRequest(false);
    this.routeChecker('helpdesk');

    /*    
    if (this.prefilledData) {
      this.readonly = false;

      this.routeChecker('/');

      let payload = {
        id: null,
        registration_id: this.prefilledData.id,
        message: this.description,
        status: 'pending',
        created_at: null,
        updated_at: null,
      };

      //console.log(payload);

      debugger;

      this.helpdeskService
        .specificConditionRequest(payload)
        .subscribe((data: any) => {
          if (data) {
            this.translate.get('helpDesk.request.validator.toastrSuccess').subscribe((data1: string) => {
              this.toastr.success(data1);
            });      
      
            localStorage.removeItem('registered');
            this.helpdeskService.setRequestId(data?.id);
            this.requestId = this.helpdeskService.getRequestId();

            this.helpdeskService.setRequestTitle(
              'Your help desk request ID ' + this.helpdeskService.getRequestId()
            );

            this.routeChecker('/');
          }
        });
    } else {
      console.log("!!!!!!!somtu3", this.form);
      this.helpdeskService.setRequest(false);
      this.routeChecker('helpdesk');
    }
*/    
  }
}
