import { Component } from '@angular/core';
import { OrderService } from '../order.service';
import { Order } from '../order';
import {
  ColInfo,
  Sheet,
  Sheet2CSVOpts,
  read,
  utils,
  writeFile,
  writeFileXLSX,
} from 'xlsx';

@Component({
  selector: 'app-order-request',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent {
  orderList: Order[] = [];
  filteredOrderList: Order[] = [];
  dateList: Date[] = [];

  listSize = 14;

  daysofWeek: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  monthNames: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  dailyHours: string[] = [];

  unit: string = 'MWh';

  loadingFinished: boolean = true;

  constructor(private orderService: OrderService) {}

  ngOnInit() {
    for(let hour = 0; hour < 24; hour++) {
      this.dailyHours.push((hour > 9 ? '' : '0') + `${hour}:00`)
    }
    this.initializeView();
  }

  initializeView() {
    this.loadingFinished = false;
    this.orderList = [];
    this.filteredOrderList = [];
    this.dateList = [];
    this.orderService.getOrders().subscribe({
      next: (data) => {
        this.parseOrders(data);
      },
    });
  }

  parseOrders(data: Order[]) {
    data.forEach((orderData) => {
      let order: Order = {
        id: orderData.id,
        client_id: orderData.client_id,
        year: orderData.year,
        month: orderData.month,
        day: orderData.day,
        hour: orderData.hour,
        consumption: orderData.consumption,
        status: orderData.status,
        created_at: orderData.created_at?.split('T')[0],
        updated_at: orderData.updated_at?.split('T')[0],
      };
      if (!order.created_at) {
        order.created_at = '[Unknown]';
      }
      this.orderList.push(order);
    });
    this.generateOrderedValues();
  }

  generateOrderedValues() {
    let startDate = new Date();
    for (let dayIndex = 0; dayIndex < this.listSize; dayIndex++) {
      for (let hourIndex = 0; hourIndex < 24; hourIndex++) {
        let date = new Date(new Date().setDate(startDate.getDate() + dayIndex));
        date.setHours(hourIndex);
        this.dateList.push(date);
        let order = this.orderList.find((order) =>
          this.isOrderRelevantToDate(date, order)
        );
        this.filteredOrderList.push(order!);
      }
    }
    //console.log(this.filteredOrderList);
    this.loadingFinished = true;
  }

  isOrderRelevantToDate(date: Date, order: Order): boolean {
    // console.log(order);
    // console.log(date);
    if (date.getFullYear() !== order.year) {
      return false;
    }
    if (date.getMonth() + 1 !== order.month) {
      return false;
    }
    if (date.getDate() !== order.day) {
      return false;
    }
    if (date.getHours() !== order.hour) {
      return false;
    }
    return true;
  }

  getDayOfWeekFromDate(date: Date): string {
    return this.daysofWeek[date.getDay()];
  }

  getDayOfWeekFromOrder(order: Order): string {
    if (!order) {
      return '';
    }
    let date = new Date(`${order.year}-${order.month}-${order.day}`);
    return this.daysofWeek[date.getDay()];
  }

  deleteOrder(orderId: number) {
    this.orderService.deleteOrder(orderId);
    //console.log('Deleted ', orderId);
    this.initializeView();
  }

  editOrder(orderId: number) {
    //console.log('Edit ', orderId);
  }

  exportToFile() {
    const headings = [['Day', 'Hour', 'Cons.', 'Unit', 'Created', 'Updated']];

    let orderData: Sheet[][] = [[]];
    let colInfo: ColInfo[] = [
      { wch: 10 },
      { wch: 4 },
      { wch: 6 },
      { wch: 4 },
      { wch: 10 },
      { wch: 10 },
    ];

    let dayIndex = 0;
    this.filteredOrderList.forEach((order) => {
      let orderEntry: Sheet[] = [];
      if (!order) {
        orderEntry.push({
          t: 'd',
          v: this.dateList[dayIndex].toLocaleDateString(),
        });
        orderEntry.push({
          t: 'n',
          v: this.dateList[dayIndex].getHours(),
        });
        orderEntry.push({ t: 's', v: '-' });
        orderEntry.push({ t: 's', v: '-' });
        orderEntry.push({ t: 's', v: '-' });
        orderEntry.push({ t: 's', v: '-' });
        orderData.push(orderEntry);
        dayIndex++;
        return;
      }
      orderEntry.push({
        t: 'd',
        v: this.dateList[dayIndex].toLocaleDateString(),
      });
      orderEntry.push({
        t: 'n',
        v: this.dateList[dayIndex].getHours(),
      });
      orderEntry.push({ t: 'n', v: order?.consumption.toString() });
      orderEntry.push({ t: 's', v: order ? 'kWH' : '' });
      orderEntry.push({ t: 'd', v: order?.created_at!.toString() });
      orderEntry.push({ t: 'd', v: order?.updated_at!.toString() });
      orderData.push(orderEntry);
      dayIndex++;
    });

    let date = new Date();
    const ws = utils.json_to_sheet([]);
    const wb = utils.book_new();
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, orderData, { origin: 'A1', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'Orders');
    ws['!cols'] = colInfo;
    //console.log(ws['!cols']);
    writeFile(
      wb,
      `Orders_${date.toISOString().split('T')[0]}_${this.listSize}d.xlsx`
    );
  }
}
