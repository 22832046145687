<title>MyEnergy Dashboard: Digitalization of electricity consumption</title>
<meta
  name="description"
  content="The MyEnergy Dashboard is a centralized control centre to monitor, optimize and analyze your energy consumption cost."
/>
<section
  class="smallHero"
  style="background-image: url(../../../assets/images/PowerStation.jpg)"
>
  <div class="container">
    <!-- <h2 class="smallHero__title">{{loggedMasterUser.fullName}} Users Registered  List</h2> -->
    <div class="smallHero__title site__title">
      {{ clientData?.name }} ({{ clientData?.shortName }}) User List
    </div>
  </div>
</section>
<div class="userListing">
  <div class="container">
    <div class="userListing__card">
      <!--
      <h4 class="userCard__title">
        {{clientData?.name}} ({{clientData?.shortName}}) <span>User list :</span>
      </h4>
      -->
      <div class="userListing__card-head">
        <mat-form-field>
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. iD" />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path
              d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
            />
          </svg>
        </mat-form-field>
        <a (click)="routeChecker('admin/user/0')" class="button theme-btn-green">Create New </a>
      </div>

      <div class="text-center" [hidden]="loadingFinished">
        <div class="spinner-border text-info" role="status"></div>
      </div>

      <div class="userListing__table">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>

          <!-- Login Column -->
          <ng-container matColumnDef="login">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{ element.fullName }}</td>
          </ng-container>

          <!-- Email Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">{{ element.email }}</td>
          </ng-container>

          <!-- Role Column -->
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let element">{{ element.role }}</td>
          </ng-container>

          <!-- Registered By Column -->
          <ng-container matColumnDef="registeredBy">
            <th mat-header-cell *matHeaderCellDef>Registered By</th>
            <td mat-cell *matCellDef="let element">
              {{ loggedMasterUser.email }}
            </td>
          </ng-container>

          <!-- Updated At Column -->
          <ng-container matColumnDef="updated_at">
            <th mat-header-cell *matHeaderCellDef>Updated At</th>
            <td mat-cell *matCellDef="let element">{{ element.updated_at }}</td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>User Status</th>
            <td mat-cell *matCellDef="let element">{{ element.status }}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
              <button
                class="theme-btn-yellow"
                (click)="editClientUser(element.id)"
              >
                <img
                  src="../../../assets/images/edit.svg"
                  width="21px"
                  alt=""
                />
              </button>
              <button
                class="theme-btn-red"
                (click)="openCofirmationDialog(element)"
              >
                <img
                  src="../../../assets/images/delete-filled.svg"
                  width="21px"
                  alt=""
                />
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>

<ng-template #dialogTemplate>
  <h1 mat-dialog-title style="font-weight: 600; font-size: 22px">
    Confirmation!
  </h1>
  <mat-dialog-content class="dialog-content">
    <h5 mat-dialog-title style="font-weight: 500">
      Are you sure you want to delete
      <br />
      {{ selectedData.fullName || selectedData.email }} as a Client User?
    </h5>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: space-between; align-content: center"
  >
    <button class="theme-btn-yellow" mat-dialog-close>No</button>
    <button class="theme-btn-red" (click)="deleteClientUser()">Yes</button>
  </mat-dialog-actions>
</ng-template>
