

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HappenedYesterdayService } from './happened-yesterday.service';
import { formatDate } from '@angular/common';
import { AdminService } from '../admin/admin.service';
import { EChartsOption } from 'echarts';
import { DatePipe } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-happened-yesterday',
  templateUrl: './happened-yesterday.component.html',
  styleUrls: ['./happened-yesterday.component.scss']
})
export class HappenedYesterdayComponent implements OnInit {
  dateFrom: string;
  dateUntil: string;
  loggedMasterUser: any;
  clientData: any;
  isLoading = true;
  chartOption: EChartsOption;

  hourlyConsumption: number[] = new Array(24).fill(0);
  hourlyPrices: number[] = new Array(24).fill(0);
  hourlyCalculatedPrice: number[] = new Array(24).fill(0);

  pricesActualData: any
  consumptionData: any

  identifiers: any
  devices: any

  totalConsumption: any
  totalPrice: any
  totalCalculatedPrice: any

  totalLocationConsumption: any
  totalLocationPrice: any
  totalLocationCalculatedPrice: any

  highestPrice: any

  selectedIdentifier: any = 'All'
  selectedDevice: any = ''

  displayedColumns: string[] = [
    'time',
    'consumption',
    'actualPrice',
    'calculatedPrice',
  ]
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('dialogTemplate2') dialogTemplate2: TemplateRef<any>;
  exportType: any = 'xlsx';
  selectedTabIndex: number
  maxDate:any
  constructor(
    private happenedService: HappenedYesterdayService,
    private adminService: AdminService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
  ) {
    this.setInitialDates();
    this.loggedMasterUser = localStorage.getItem('userToken');
    if (this.loggedMasterUser) {
      this.loggedMasterUser = JSON.parse(this.loggedMasterUser);
      this.adminService.getClient(this.loggedMasterUser.client_id).subscribe((data) => {
        this.clientData = data;
      });
    }
  }

  setInitialDates() {
    let date = new Date();
    date.setDate(date.getDate());
    this.dateUntil = formatDate(date, 'yyyy-MM-dd', 'en');
    date.setDate(date.getDate() - 1);
    this.dateFrom = formatDate(date, 'yyyy-MM-dd', 'en');
    this.maxDate=this.dateFrom
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.updateChartOption();
    this.getData()
  }
  getData(){
    this.happenedService.getYesterdayActualPrices(this.dateFrom).subscribe((data) => {
      this.pricesActualData = data
      this.processPrices(this.pricesActualData);
      this.happenedService.getHappenedYesterday({ dateFrom: this.dateFrom, dateUntil: this.dateUntil }).subscribe((data) => {
        this.consumptionData = data
        this.getOverallHappened(this.consumptionData);
        this.extractIdentifiers()
      });
    });

  }
  extractIdentifiers() {
    const identifiers: any = [];
    const devices: any = [];
    identifiers.push('All')
    this.consumptionData.forEach((location: any) => {
      if (location.identifier) {
        identifiers.push(location.identifier);
      }
      location.devices.forEach((device: any, index: number) => {
        if (device) {
          devices.push({ label: `Device (${devices.length + 1}) - ${device.description}`, value: device.id, location: device.location_id });
        }
      });
    });
    this.identifiers = identifiers;
    this.devices = devices
  }
  formatDate() {
    return this.getFormattedDateWithSuffix(this.dateFrom);  // Format date
  }
  getFormattedDateWithSuffix(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate();
    const suffix = this.getDaySuffix(day);
    const monthYear = this.datePipe.transform(date, 'MMM yyyy');
    return `${this.padZero(day)}${suffix} ${monthYear}`;
  }

  getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  padZero(day: number): string {
    return day < 10 ? '0' + day : day.toString();
  }

  getOverallHappened(data: any) {
    let hourlyConsumption = new Array(24).fill(0);
    data.forEach((region: any) => {
      region.devices.forEach((device: any) => {
        device.data.forEach((reading: any) => {
          let datetime = new Date(reading.datetime);
          let hour = datetime.getUTCHours();
          let consumption = parseFloat(reading.consumption);
          hourlyConsumption[hour] += consumption;
        });
      });
    });
    hourlyConsumption = hourlyConsumption.map(value => parseFloat((value / 1000).toFixed(2)));
    this.hourlyConsumption = hourlyConsumption;
    for (let i = 0; i < 24; i++) {
      this.hourlyCalculatedPrice[i] = parseFloat((this.hourlyConsumption[i] * this.hourlyPrices[i]).toFixed(2));
    }
    this.updateChartOption();
    this.updateTableData(1)
    this.isLoading = false;
    this.selectedTabIndex = 0
  }
  updateTableData(index: number) {
    let dataSource: any = []
    let hourlyConsumption = new Array(24).fill(0);

    if (this.selectedIdentifier !== 'All') {

      this.consumptionData[index].devices.forEach((device: any) => {
        device.data.forEach((reading: any) => {
          let datetime = new Date(reading.datetime);
          let hour = datetime.getUTCHours();
          let consumption = parseFloat(reading.consumption);
          hourlyConsumption[hour] += consumption;
        });
      });
      hourlyConsumption = hourlyConsumption.map(value => parseFloat((value / 1000).toFixed(2)));
      for (let i = 0; i < 24; i++) {
        let obj = {
          hour: i,
          consumption: hourlyConsumption[i],
          actualPrice: this.hourlyPrices[i].toFixed(2),
          calculatedPrice: parseFloat((hourlyConsumption[i] * this.hourlyPrices[i]).toFixed(2))
        }

        dataSource.push(obj)
      }
    } else {
      this.consumptionData.forEach((region: any) => {
        region.devices.forEach((device: any) => {
          device.data.forEach((reading: any) => {
            let datetime = new Date(reading.datetime);
            let hour = datetime.getUTCHours();
            let consumption = parseFloat(reading.consumption);
            hourlyConsumption[hour] += consumption;
          });
        });
      });
      hourlyConsumption = hourlyConsumption.map(value => parseFloat((value / 1000).toFixed(2)));
      for (let i = 0; i < 24; i++) {
        let obj = {
          hour: i,
          consumption: hourlyConsumption[i],
          actualPrice: this.hourlyPrices[i].toFixed(2),
          calculatedPrice: parseFloat((hourlyConsumption[i] * this.hourlyPrices[i]).toFixed(2))
        }

        dataSource.push(obj)
      }
    }



    this.totalLocationConsumption = dataSource.reduce((acc: any, curr: any) => acc + curr.consumption, 0);
    this.totalLocationPrice = dataSource.reduce((acc: any, curr: any) => acc + Number(curr.actualPrice), 0);
    this.totalLocationCalculatedPrice = dataSource.reduce((acc: any, curr: any) => acc + curr.calculatedPrice, 0);

    this.dataSource = new MatTableDataSource(dataSource);
    this.dataSource.sort = this.sort;

  }
  getHourName(hour: number) {
    let hourName;
    if (hour === 9) {
      hourName = `0${hour}:00-${hour + 1}:00 hour`;
    } else if (hour < 9) {
      hourName = `0${hour}:00-0${hour + 1}:00 hour`;
    } else {
      hourName = `${hour}:00-${hour + 1}:00 hour`;
    }
    return `${hourName}`;
  }
  getHourNameForTable(hour: number) {
    let hourName;
    let date = this.dateFrom.split("-").reverse().join('.')
    if (hour === 9) {
      hourName = `0${hour}:00-${hour + 1}:00`;
    } else if (hour < 9) {
      hourName = `0${hour}:00-0${hour + 1}:00`;
    } else {
      hourName = `${hour}:00-${hour + 1}:00`;
    }
    return `${date} ${hourName}`;
  }
  generateChart(){
    let date = new Date(this.dateFrom);
    date.setDate(date.getDate());
    this.dateFrom = formatDate(date, 'yyyy-MM-dd', 'en');
    date.setDate(date.getDate()+1);
    this.dateUntil = formatDate(date, 'yyyy-MM-dd', 'en');
    this.isLoading = true;
    this.getData()
  }

  processPrices(data: any) {
    data.forEach((price: any, index: number) => {
      let priceValue = parseFloat(price.price) / 100000;
      this.hourlyPrices[index] = priceValue;
    });

    this.hourlyPrices = this.hourlyPrices.map(value => parseFloat(value.toFixed(2)));
  }
  capitalizeFirstLetter(string: string) {
    return string.replace(/\b\w/g, char => char.toUpperCase());
  }


  updateChartOption() {
    this.totalPrice = 0;
    this.highestPrice = {
      hour: 0,
      price: 0
    };

    for (let i = 0; i < this.hourlyPrices.length; i++) {
      this.totalPrice += this.hourlyPrices[i];
      if (this.hourlyPrices[i] > this.highestPrice.price) {
        this.highestPrice.price = this.hourlyPrices[i];
        this.highestPrice.hour = i;
      }
    }
    this.totalConsumption = this.hourlyConsumption.reduce((acc: any, curr: any) => acc + curr, 0);
    this.totalCalculatedPrice = this.hourlyCalculatedPrice.reduce((acc: any, curr: any) => acc + curr, 0);

    this.chartOption = {
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: ['Consumption', 'Actual Price', 'Consumption Cost'],
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
        {
          show: false,
          type: 'slider',
          top: '90%',
          start: 0,
          end: 100,
        },
      ],
      xAxis: {
        name: '',
        type: 'category',
        data: Array.from({ length: 24 }, (_, i) => `${i}:00`),
        axisLabel: {
          rotate: 35,
        },
      },
      yAxis: [
        {
          type: 'value',
          name: 'Consumption (kWh)',
          position: 'left',
        },
        {
          type: 'value',
          name: 'EUR / kWh',
          position: 'right',
          axisLine: {
            lineStyle: { color: '#0083cf' }, // Matching the color of "Calculated Consumption Price"
          },
          axisLabel: {
            formatter: '{value} €',
          },
        },
      ],
      series: [
        {
          name: 'Consumption Cost',
          type: 'line',
          data: this.hourlyCalculatedPrice,
          lineStyle: { color: '#00bf63', width: 2 },
          itemStyle: { color: '#00bf63' },
          areaStyle: { color: '#00bf6350', opacity: 0.5 },
          smooth: true,
          yAxisIndex: 1, // Linked to the second y-axis
        },
        {
          name: 'Consumption',
          type: 'line',
          data: this.hourlyConsumption,
          lineStyle: { color: '#000000', width: 2 },
          itemStyle: { color: '#000000' },
          smooth: true,
          yAxisIndex: 0, // Linked to the first y-axis
        },
        {
          name: 'Actual Price',
          type: 'line',
          data: this.hourlyPrices,
          lineStyle: { color: '#777777', width: 2 },
          itemStyle: { color: '#777777' },
          smooth: true,
          yAxisIndex: 1, // Linked to the second y-axis
        },
      ],
    };
  }

  setLocation() {
    this.isLoading = true;
    this.hourlyCalculatedPrice = []
    if (this.selectedIdentifier !== 'All') {
      const filteredLocation = this.consumptionData.find((element: any) => element.identifier === this.selectedIdentifier);
      const index = this.consumptionData.findIndex((element: any) => element.identifier === this.selectedIdentifier);
      this.onLocationChange(index+1)
      let hourlyConsumption = new Array(24).fill(0);

      filteredLocation.devices.forEach((device: any) => {
        device.data.forEach((reading: any) => {
          let datetime = new Date(reading.datetime);
          let hour = datetime.getUTCHours();
          let consumption = parseFloat(reading.consumption);
          hourlyConsumption[hour] += consumption;
        });
      });

      hourlyConsumption = hourlyConsumption.map(value => parseFloat((value / 1000).toFixed(2)));
      this.hourlyConsumption = hourlyConsumption;
      for (let i = 0; i < 24; i++) {
        this.hourlyCalculatedPrice[i] = parseFloat((this.hourlyConsumption[i] * this.hourlyPrices[i]).toFixed(2));
      }
      this.updateChartOption();
      this.isLoading = false;
    } else {
      this.getOverallHappened(this.consumptionData)
    }
  }
  setDevice() {
    // if (this.selectedDevice) {
    //   this.isLoading = true;
    //   this.hourlyCalculatedPrice = []
    //   const filteredLocation = this.consumptionData.find((element: any) => element.id === this.selectedDevice.location);
    //   this.selectedIdentifier = filteredLocation.identifier

    //   const filtereDevice = filteredLocation.devices.find((element: any) => element.id == this.selectedDevice.value)

    //   let hourlyConsumption = new Array(24).fill(0);

    //   filtereDevice.data.forEach((reading: any) => {
    //     let datetime = new Date(reading.datetime);
    //     let hour = datetime.getUTCHours();
    //     let consumption = parseFloat(reading.consumption);
    //     hourlyConsumption[hour] += consumption;
    //   });

    //   hourlyConsumption = hourlyConsumption.map(value => parseFloat((value/1000).toFixed(2)));
    //   this.hourlyConsumption = hourlyConsumption;
    //   for (let i = 0; i < 24; i++) {
    //     this.hourlyCalculatedPrice[i] = parseFloat((this.hourlyConsumption[i] * this.hourlyPrices[i]).toFixed(2));
    //   }
    //   this.updateChartOption();
    //   this.isLoading = false;
    // } else {
    // }
    this.selectedIdentifier = ''
    this.getOverallHappened(this.consumptionData)
  }
  onLocationChange(event: any) {
    this.selectedTabIndex = event
    this.updateTableData(event - 1)
  }
  formatNumber(value: any) {
    let roundedValue = value.toFixed(2).replace('.', '.');
    return roundedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  openExportDailog() {
    this.dialog.open(this.dialogTemplate2);
  }
  exportPlans() {
    let payload: any = {
      dateFrom: this.dateFrom,
      dateUntil: this.dateUntil,
      format: this.exportType
    };
    let requestOptions = {
      responseType: 'blob' as 'json',
    };
    this.happenedService.exportData(payload, requestOptions).subscribe(
      (response: Blob) => {
        this.dialog.closeAll();
        const blobUrl = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `Happened Last Week.${this.exportType}`;
        link.click();
        window.URL.revokeObjectURL(blobUrl);
        link.remove();
        this.exportType = '';
      },
      (error) => {
        console.error('Error exporting Happened Last Week:', error);
      }
    );
  }
}

