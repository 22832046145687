<section
  class="smallHero"
  style="background-image: url(../../../assets/images/PowerStation.jpg)"
>
  <div class="container">
    <h2 class="smallHero__title">{{clientData?.name}} ({{clientData?.shortName}}) User Registration</h2>
  </div>
</section>
<div class="createUser">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-11">
        <div class="userCard">
          <!-- <h5 class="section-subtitle">Fill in the User details:</h5> -->
          <form #form="ngForm" (ngSubmit)="createUser()" ngNativeValidate>
            <div class="userCard__mainForm">
              <h4 class="userCard__title">
                {{clientData?.name}} ({{clientData?.shortName}}) <span>User Registration :</span>
              </h4>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="fName">First Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="First Name"
                      minlength="3"
                      maxlength="50"
                      required="true"
                      [(ngModel)]="userDetails.firstName"
                      #firstName="ngModel"
                      name="firstName"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="fName">Last Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Last Name"
                      minlength="3"
                      maxlength="100"
                      required="true"
                      [(ngModel)]="userDetails.lastName"
                      #lastName="ngModel"
                      name="lastName"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="fName">E-mail</label>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="E-mail"
                      minlength="5"
                      maxlength="100"
                      required="true"
                      [(ngModel)]="userDetails.email"
                      name="email"
                      (blur)="validateEmail()"
                    />
                    <div *ngIf="emailError" style="color: red">
                      {{ emailError }}
                    </div>
                  </div>
                </div>




                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="userRole">User Role</label>
                    <select
                      class="form-control"
                      required="true"
                      [(ngModel)]="userDetails.userRole"
                      #userRole="ngModel"
                      name="userRole"
                    >
                    <option selected value="">Please select role</option>
                      <option value="user">User</option>
                      <option value="master user">Master User</option>
                    </select>
                  </div>
                </div>

              </div>
            </div>
            <div class="userCard__tableForm">
              <h4 class="userCard__title">{{clientData?.name}} ({{clientData?.shortName}})<span> User Registration :</span></h4>
              <div class="userCard__table">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Source Table</th>
                      <th>Attribute</th>
                      <th>Permissions</th>
                      <th>Filter Column</th>
                      <!-- <th>Filter Value</th> -->
                    </tr>
                  </thead>

                  <tbody>
                    <tr *ngFor="let right of accessRights">
                      <th>{{ right.sourceTable }}</th>
                      <td>
                        <div class="form-select">
                          <!-- <select class="form-control">
                            <option *ngFor="let attr of right.attribute">
                              {{ attr }}
                            </option>
                          </select> -->
                          <input
                          type="text"
                          class="form-control"
                          [value]="right.attribute"
                        />
                        </div>
                      </td>

                      <td>
                        <div class="table-checkbox">
                          <div class="CheckBox">
                            <input type="checkbox" [checked]="right.crud.c" />
                            <label> Create</label>
                          </div>
                          <div class="CheckBox">
                            <input type="checkbox" [checked]="right.crud.r" />
                            <label>Read</label>
                          </div>
                          <div class="CheckBox">
                            <input type="checkbox" [checked]="right.crud.u" />
                            <label>Update</label>
                          </div>
                          <div class="CheckBox">
                            <input type="checkbox" [checked]="right.crud.d" />
                            <label>Delete</label>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="form-select">
                          <!-- <select class="form-control">
                            <option>{{ right.filterColumn }}</option>
                          </select> -->
                          <mat-form-field>
                            <mat-select [formControl]="selectControl" multiple>
                              <!-- <mat-select-trigger>
                                {{ getDisplayText() }}
                              </mat-select-trigger> -->
                              <mat-option *ngFor="let option of right.filterColumn" [value]="option">{{option}}</mat-option>
                            </mat-select>
                          </mat-form-field>

                        </div>
                      </td>

                      <!-- <td>
                        <input
                          type="text"
                          class="form-control"
                          [value]="right.filterValue"
                        />
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="userCard__button">
              <button
                type="submit"
                class="btn button"
              >
                Submit
              </button>
              <a (click)="routeChecker('admin/users')">
                <button type="button" class="btn button-border">Cancel</button>
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
