import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from '../user/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivityLogsService {
  baseUrl: string = environment.baseUrlAPI;
  activityLogsUrl:string =this.baseUrl+'/useractivitylogs'
  loggedMasterUser:any

  constructor(private userService: UserService, private hc: HttpClient) {
    this.loggedMasterUser = localStorage.getItem('userToken');
    if (this.loggedMasterUser) {
      this.loggedMasterUser = JSON.parse(this.loggedMasterUser);
    }
  }

  getActivityLogs(data: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.post(this.activityLogsUrl, data, { headers });
  }
  getActivities(): Observable<any> {
    // const headers = new HttpHeaders({
    //   'x-authorization-token': this.loggedMasterUser.token,
    // });
    return this.hc.get(this.activityLogsUrl+'/activities');
  }

}
