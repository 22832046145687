import { AuthInterceptor } from './auth.interceptor';
import { NgModule } from '@angular/core';
import { UserLoginComponent } from './user-login.component';
import { SharedModule } from '../shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SignUpComponent } from './sign-up/sign-up.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import { RecaptchaModule } from 'ng-recaptcha';
import { ClientUserPasswordSetupComponent } from './client-user-password-setup/client-user-password-setup.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { SpecificConditionsComponent } from './specific-conditions/specific-conditions.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/langs/i18n/', '.json');
}
@NgModule({
  declarations: [UserLoginComponent, SignUpComponent, ClientUserPasswordSetupComponent, SpecificConditionsComponent, ForgotPasswordComponent],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDialogModule,
    MatRadioModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],

  exports: [UserLoginComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    TranslateService
  ],
})
export class UserModule {}
