<section class="smallHero" style="background-image: url(../../assets/images/PowerStation.jpg)">
  <div class="container">
    <div class="row">
      <div class="col smallHero__title site__title">Activity Logs</div>
    </div>
    <div class="row">
      <div class="col smallHero__title site__title2">
        {{ clientData?.name + " ( " + clientData?.shortName + " ) " }}
      </div>
    </div>
  </div>
</section>


<div class="requestList">
  <div class="container">
    <div class="userListing">
      <div class="container">
        <div class="userListing__card">
          <h5>Filters:</h5>
          <div class="userListing__card-head">
            <select [(ngModel)]="payload.activity" name="activity" id="">
              <option value="" selected>Select Activity</option>
              <option *ngFor="let item of activities" [value]="item.key">{{item.display}}</option>
            </select>

            <select [(ngModel)]="selectedUser" name="activity" id="">
              <option value="" selected>Select User</option>
              <option *ngFor="let item of userlist" [value]="item.id">{{item.fullName}}</option>
            </select>

            <mat-form-field>
              <mat-label *ngIf="!payload.dateFrom">Choose a date from</mat-label>
              <input [(ngModel)]="payload.dateFrom" matInput [matDatepicker]="picker1">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
              <mat-label *ngIf="!payload.dateTo">Choose a date to</mat-label>
              <input [(ngModel)]="payload.dateTo" matInput [matDatepicker]="picker2">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

            <button (click)="getActivityLogs()" class="button theme-btn-green">Submit</button>
          </div>
          <div class="text-center" [hidden]="loadingFinished">
            <div class="spinner-border text-info" role="status"></div>
          </div>



          <div class="userListing__table">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
              <ng-container matColumnDef="user_id">
                <th mat-header-cell *matHeaderCellDef>User</th>
                <td mat-cell *matCellDef="let element">
                  {{ getCreatedByName(element.user_id)}} - User ID {{element.user_id}}
                </td>
              </ng-container>

              <ng-container matColumnDef="activity">
                <th mat-header-cell *matHeaderCellDef>Resource</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.activity }}
                </td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Operation</th>
                <td mat-cell *matCellDef="let element">
                  {{ getMethodName(element.description) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef>Date & Time</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.created_at }}
                </td>
              </ng-container>



              <ng-container matColumnDef="ip_address">
                <th mat-header-cell *matHeaderCellDef>Ip Address</th>
                <td mat-cell *matCellDef="let element">
                  {{element.ip_address }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Details</th>
                <td mat-cell *matCellDef="let element">
                  <div class="actionsBtn" *ngIf="element.description!=='GET' && element.description!=='DELETE'">
                    <button type="button" class="borderBtn" data-toggle="modal" data-target="#viewModal"
                      (click)="viewModal(element)">
                      <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                        <path
                          d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                      </svg>
                    </button>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <mat-paginator [pageSizeOptions]="[15, 20, 25]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>























<div class="modal fade detailsModel" id="viewModal" tabindex="-1" role="dialog" aria-labelledby="viewModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="viewModalLabel">
          Activity Log Details:
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Iterate over the top-level items of the parsedData -->
        <div *ngFor="let item of parsedData">
          <!-- Check if the current item is the consumptions array -->
          <ng-container *ngIf="item[0] === 'consumptions'; else otherItem">
            <h6>{{ capitalizeFirstLetter(item[0]) }} :</h6>
            <div *ngFor="let consumption of item[1]">
              <p>Day: {{ consumption.day }}</p>
              <p>Hour: {{ consumption.hour }}</p>
              <p>Consumption: {{ consumption.consumption }} kWh</p>
              <p>Max: {{ consumption.max }} kWh</p>
              <p>Min: {{ consumption.min }} kWh</p>
              <p>Year: {{ consumption.year }}</p>
              <p>Month: {{ consumption.month }}</p>
              <hr>
            </div>
          </ng-container>
          <ng-template #otherItem>
            <!-- Display other top-level items normally -->
            <p>{{ capitalizeFirstLetter(item[0]) }}: {{ item[1] }}</p>
          </ng-template>
        </div>
      </div>

    </div>
  </div>
</div>
