import { Component, OnInit } from '@angular/core';
import { ApprovalPageService } from './approval-page.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-approval-page',
  templateUrl: './approval-page.component.html',
  styleUrls: ['./approval-page.component.scss'],
})
export class ApprovalPageComponent implements OnInit {
  approvedRegistrations: any;
  pendingRegistrations:any
  declinedRegistrations:any
  allClients:any
  selectedData:any
  constructor(private approvalServce: ApprovalPageService,private toastr: ToastrService) {}

  ngOnInit() {
    this.getAllPendingRegistration();
  }
  getAllPendingRegistration() {
    this.approvalServce.masterUserRegistration().subscribe((data) => {
      if (data) {
        this.pendingRegistrations=data.filter((item: any) => item.status === 'pending');
        this.declinedRegistrations=data.filter((item: any) => item.status === 'inactive');
        this.approvedRegistrations=data.filter((item: any) => item.status === 'active');
        this.approvalServce.clientsRegisters().subscribe((resdata) => {
          this.allClients=resdata
        });
      }
    });
  }
  declineRegistration(id: number, data: any) {
    data.status="inactive"
    this.approvalServce.changeRegistrationStatus(id, data).subscribe((data) => {
      this.toastr.success('Success', 'Status changed!');
      this.getAllPendingRegistration();
    });

  }
  revertRegistration(id: number, data: any) {
    data.status="pending"
    this.approvalServce.changeRegistrationStatus(id, data).subscribe((data) => {
      this.toastr.success('Success', 'Status changed!');
      this.getAllPendingRegistration();
    });

  }
  approveRegistration(id: number, data: any) {
    data.status="active"
    this.approvalServce.changeRegistrationStatus(id, data).subscribe((data) => {
      this.toastr.success('Success', 'Status changed!');
      this.getAllPendingRegistration();
    });

    let clientPayload = {
      id: null,
      name: data.officialName,
      address: data.officialAddress,
      region: data.country,
      shortName:data.shortName,
      webpage: data.website,
      phone: data.phone,
      status: 'active',
      created_at: null,
      updated_at: null,
    };

    let userPayload = {
      id: null,
      name: data.officialName,
      client_id: 4,
      login: data.email,
      email: data.email,
      password: data.password,
      fullName:
        data.userFirstName + ' ' + data.userLastName,
      token: '111222',
      role: 'master user',
      created_at: null,
      updated_at: null,
    };
    this.approvalServce.addClients(clientPayload).subscribe((data) => {
      if (data) {
        userPayload.client_id = data.id;
        this.approvalServce.addUser(userPayload).subscribe((resdata) => {
          if (resdata) {
            this.toastr.success('Success', 'Master Client Registartion is Approved!');
          }
        });
      }
    });
  }
  viewModal(data:any){
    this.selectedData=data
  }
  getInitials(name: string) {
    let initials = name.split(' ').map((n)=>n[0]).join('');
    return initials.toUpperCase();
  }
}
